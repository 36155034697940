import React, {createContext, useContext, useEffect, useState} from 'react';

import {useFilterProvider} from '../Filter/Filter.Context';
import {
  fetchBottles,
  fetchCarbonBreakdown,
  fetchCarbonEquivalency,
  fetchCarbonFootprint,
  fetchCarbonNeutrality,
  fetchCarbonOffset,
  fetchTrees,
} from './Graph.Api';
import {
  GraphContextInterface,
  GraphInterface,
  GraphStateKeys,
  SummaryKey,
} from './Graph.Interface';

//todo this must have a list of the graphs that have data nad loading props
const GraphContext = createContext<GraphInterface>({} as GraphInterface);

const GraphProvider = ({children}: GraphContextInterface) => {
  const [graphs, setGraphs] = useState<GraphInterface>({} as GraphInterface);
  const {filters} = useFilterProvider();

  const handleStateDataUpdate = (
    stateKey: GraphStateKeys,
    data: any,
    total?: number | null,
  ) => {
    setGraphs((prevState: any) => ({
      ...prevState,
      [stateKey]: {
        ...prevState[stateKey],
        total,
        data,
      },
    }));
  };
  const handleSummaryUpdate = (stateKey: SummaryKey, value: number) => {
    setGraphs((prevState: any) => ({
      ...prevState,
      summaries: {
        ...prevState.summaries,
        [stateKey]: value,
      },
    }));
  };
  const toggleStateLoading = (stateKey: GraphStateKeys, loading: boolean) => {
    setGraphs((prevState: any) => ({
      ...prevState,
      [stateKey]: {
        ...prevState[stateKey],
        loading,
      },
    }));
  };

  //carbonFootprint
  useEffect(() => {
    toggleStateLoading('carbonFootprint', true);
    if (filters) {
      fetchCarbonFootprint(filters)
        .then(({total, data}: any) => {
          handleSummaryUpdate('carbonFootprint', total);
          handleStateDataUpdate('carbonFootprint', data, total);
        })
        .catch()
        .finally(() => toggleStateLoading('carbonFootprint', false));
    }
  }, [filters]);

  //carbonOffset
  useEffect(() => {
    toggleStateLoading('carbonOffset', true);
    if (filters) {
      fetchCarbonOffset(filters)
        .then(({total, data}: any) => {
          handleSummaryUpdate('carbonOffset', total);
          handleStateDataUpdate('carbonOffset', data, total);
        })
        .catch()
        .finally(() => toggleStateLoading('carbonOffset', false));
    }
  }, [filters]);

  //carbon neutrality
  useEffect(() => {
    toggleStateLoading('carbonNeutrality', true);
    if (filters) {
      fetchCarbonNeutrality(filters)
        .then(({total, data}: any) => {
          handleSummaryUpdate('carbonNeutrality', total);
          handleStateDataUpdate('carbonNeutrality', data);
        })
        .catch()
        .finally(() => toggleStateLoading('carbonNeutrality', false));
    }
  }, [filters]);

  // carbonEquivalency
  useEffect(() => {
    toggleStateLoading('carbonEquivalency', true);
    if (filters) {
      fetchCarbonEquivalency(filters)
        .then(({data}: any) => {
          handleStateDataUpdate('carbonEquivalency', data);
        })
        .catch()
        .finally(() => toggleStateLoading('carbonEquivalency', false));
    }
  }, [filters]);

  //carbonBreakdown
  useEffect(() => {
    toggleStateLoading('carbonBreakdown', true);
    if (filters) {
      fetchCarbonBreakdown(filters)
        .then(({total, data}: any) => {
          handleStateDataUpdate('carbonBreakdown', data, total);
        })
        .catch()
        .finally(() => toggleStateLoading('carbonBreakdown', false));
    }
  }, [filters]);

  //ecoRewards:

  //carbonBreakdown
  useEffect(() => {
    toggleStateLoading('treeData', true);
    if (filters) {
      fetchTrees(filters)
        .then(({data, total}: any) => {
          handleStateDataUpdate('treeData', data, total);
        })
        .catch()
        .finally(() => toggleStateLoading('treeData', false));
    }
  }, [filters]);

  useEffect(() => {
    toggleStateLoading('bottleData', true);
    if (filters) {
      fetchBottles(filters)
        .then(({data, total}: any) => {
          handleStateDataUpdate('bottleData', data, total);
        })
        .catch()
        .finally(() => toggleStateLoading('bottleData', false));
    }
  }, [filters]);

  return (
    <GraphContext.Provider value={graphs}>{children}</GraphContext.Provider>
  );
};

const useGraphProvider = () => useContext(GraphContext);

export {GraphProvider, useGraphProvider};

export default GraphContext;
