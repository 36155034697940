import {CardHeader, DoughnutGraph} from '@ekko-earth/dashboard-components';

import {useGraphProvider} from '../../../context/Graph/Graph.Context';
import {CardContentWrapper} from '../../DashboardSections/DashboardSection.styles';
import NoData from '../../NoData';
import {SkeletonLoader} from '../../SkeletonLoader';
import {CardContent} from '../CarbonOffset.styles';

const CarbonCategory = () => {
  const {carbonBreakdown: {loading = true, data = {}, total = 0} = {}} =
    useGraphProvider();

  if (loading) {
    return (
      <CardContentWrapper height={300} width={440}>
        <SkeletonLoader />
      </CardContentWrapper>
    );
  }

  return (
    <>
      <CardHeader heading={'Carbon Categories'} />
      <CardContent>
        <CardContentWrapper height={250} width={420}>
          {!loading && data ? (
            <DoughnutGraph
              data={data}
              header={`${Number(total).toLocaleString()}`}
              subHeader={'CO2/Kg'}
            />
          ) : (
            <NoData componentName={'Carbon Category data.'} />
          )}
        </CardContentWrapper>
      </CardContent>
    </>
  );
};

export default CarbonCategory;
