/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCarbonFootprint = /* GraphQL */ `
  query GetCarbonFootprint($input: QueryFilter) {
    getCarbonFootprint(input: $input) {
      carbonImpactData {
        data {
          date
          value
        }
        total
      }
      errors {
        ... on Error {
          description
          message
          path
        }
        ... on NotFoundError {
          message
          path
          resource
        }
        ... on ValidationError {
          message
          path
          validation
        }
        ... on UnauthorizedError {
          message
          path
          resource
        }
      }
    }
  }
`;
export const getCarbonOffset = /* GraphQL */ `
  query GetCarbonOffset($input: QueryFilter) {
    getCarbonOffset(input: $input) {
      carbonOffsetData {
        data {
          date
          value
        }
        total
      }
      errors {
        ... on Error {
          description
          message
          path
        }
        ... on NotFoundError {
          message
          path
          resource
        }
        ... on ValidationError {
          message
          path
          validation
        }
        ... on UnauthorizedError {
          message
          path
          resource
        }
      }
    }
  }
`;
export const getCarbonEquivalency = /* GraphQL */ `
  query GetCarbonEquivalency($input: QueryFilter) {
    getCarbonEquivalency(input: $input) {
      carbonEquivalance {
        phonesCharged
        milesByCar
      }
      errors {
        ... on Error {
          description
          message
          path
        }
        ... on NotFoundError {
          message
          path
          resource
        }
        ... on ValidationError {
          message
          path
          validation
        }
        ... on UnauthorizedError {
          message
          path
          resource
        }
      }
    }
  }
`;
export const getCarbonNeutrality = /* GraphQL */ `
  query GetCarbonNeutrality($input: QueryFilter) {
    getCarbonNeutrality(input: $input) {
      carbonNeutrality {
        data {
          date
          values
        }
      }
      errors {
        ... on Error {
          description
          message
          path
        }
        ... on NotFoundError {
          message
          path
          resource
        }
        ... on ValidationError {
          message
          path
          validation
        }
        ... on UnauthorizedError {
          message
          path
          resource
        }
      }
    }
  }
`;
export const getCarbonImpactCategories = /* GraphQL */ `
  query GetCarbonImpactCategories($input: QueryFilter) {
    getCarbonImpactCategories(input: $input) {
      carbonCategoriesImpactData {
        data {
          travel
          groceries
          lifestyle
          shopping
          other
        }
        total
      }
      errors {
        ... on Error {
          description
          message
          path
        }
        ... on NotFoundError {
          message
          path
          resource
        }
        ... on ValidationError {
          message
          path
          validation
        }
        ... on UnauthorizedError {
          message
          path
          resource
        }
      }
    }
  }
`;
export const getTotalTrees = /* GraphQL */ `
  query GetTotalTrees($input: QueryFilter) {
    getTotalTrees(input: $input) {
      trees
      data {
        date
        value
      }
    }
  }
`;
export const getTotalBottles = /* GraphQL */ `
  query GetTotalBottles($input: QueryFilter) {
    getTotalBottles(input: $input) {
      bottles
      data {
        date
        value
      }
    }
  }
`;
export const getUserByCognitoId = /* GraphQL */ `
  query GetUserByCognitoId {
    getUserByCognitoId {
      user {
        cognitoUUID
        firstName
        lastName
        email
        brandId
        organisationId
        createdAt
        updatedAt
      }
      settings {
        products {
          subscribed
          preferences {
            ecoRewards {
              transactionsPerTree
              transactionsPerBottle
            }
          }
        }
        logo
        name
      }
      brands {
        _id
        name
      }
    }
  }
`;
