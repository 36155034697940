import {BarGraph, Card, CardHeader} from '@ekko-earth/dashboard-components';

import {useGraphProvider} from '../../context/Graph/Graph.Context';
import {CardContentWrapper} from '../DashboardSections/DashboardSection.styles';
import {Equivalency} from '../EquivalencyCard';
import NoData from '../NoData';
import {SkeletonLoader} from '../SkeletonLoader';
import {CardContent, Container, Wrapper} from './EcoRewards.styles';

const EcoRewards = () => {
  const {bottleData, treeData} = useGraphProvider();

  return (
    <Wrapper>
      <Container>
        <Card hasShadow>
          {bottleData?.loading ? (
            <CardContentWrapper height={250} width={350}>
              <SkeletonLoader />
            </CardContentWrapper>
          ) : (
            <>
              <CardHeader heading={'Bottles'} />
              <CardContent>
                <CardContentWrapper height={220} width={330}>
                  {!bottleData?.loading && bottleData?.total ? (
                    <BarGraph
                      header={`${Number(
                        bottleData?.total || 0,
                      ).toLocaleString()}`}
                      subHeader="Bottles collected"
                      data={bottleData?.data || []}></BarGraph>
                  ) : (
                    <NoData componentName={'Bottles data'} />
                  )}
                </CardContentWrapper>
              </CardContent>
            </>
          )}
        </Card>
        <Card hasShadow>
          {treeData?.loading ? (
            <CardContentWrapper height={250} width={350}>
              <SkeletonLoader />
            </CardContentWrapper>
          ) : (
            <>
              <CardHeader heading={'Trees'} />
              <CardContent>
                <CardContentWrapper height={220} width={330}>
                  {!treeData?.loading && treeData?.total ? (
                    <BarGraph
                      header={`${Number(
                        treeData?.total || 0,
                      ).toLocaleString()}`}
                      subHeader="Trees collected"
                      data={treeData?.data || []}></BarGraph>
                  ) : (
                    <NoData componentName={'Tree data'} />
                  )}
                </CardContentWrapper>
              </CardContent>
            </>
          )}
        </Card>
        <Card>
          <Equivalency />
        </Card>
      </Container>
    </Wrapper>
  );
};

export default EcoRewards;
