import {
  BottleIcon,
  Card,
  FooterLogo,
  PlaneIcon,
  ProjectCard,
  RefreshIcon,
  TreeIcon,
} from '@ekko-earth/dashboard-components';

import {useGraphProvider} from '../../context/Graph/Graph.Context';
import {CardContentWrapper} from '../DashboardSections/DashboardSection.styles';
import {SummaryCard} from '../SummaryCard';
import {
  CardContent,
  Container,
  DashboardRowContainer,
  FlexContainer,
  SummaryCardsContainer,
  Wrapper,
} from './CarbonOffset.styles';
import {CarbonCategory, CarbonFootprint, CarbonOffset} from './Graphs';
import CarbonNeutrality from './Graphs/CarbonNeutrality';
import ProjectLocation from './projectLocation.png';

const CarbonInput = () => {
  const {carbonFootprint, bottleData, treeData, carbonOffset} =
    useGraphProvider();

  return (
    <>
      <SummaryCardsContainer>
        <SummaryCard
          heading="Carbon Footprint"
          icon={<PlaneIcon />}
          value={`${Number(carbonFootprint?.total || 0).toLocaleString()}`}
          loading={carbonFootprint?.loading}
          unit="CO2/Kg"></SummaryCard>
        <SummaryCard
          heading="Carbon Offset"
          icon={<RefreshIcon />}
          value={`${Number(carbonOffset?.total || 0).toLocaleString()}`}
          loading={carbonOffset?.loading}
          unit="CO2/Kg"></SummaryCard>
        <SummaryCard
          heading="Bottles"
          icon={<BottleIcon />}
          value={`${Number(bottleData?.total || 0).toLocaleString()}`}
          loading={bottleData?.loading}
          unit="Collected"></SummaryCard>
        <SummaryCard
          heading="Trees"
          icon={<TreeIcon />}
          value={`${Number(treeData?.total || 0).toLocaleString()}`}
          loading={treeData?.loading}
          unit="Planted"></SummaryCard>
      </SummaryCardsContainer>
      <Wrapper>
        <Container>
          <DashboardRowContainer>
            <FlexContainer>
              <Card hasShadow>
                <CarbonFootprint />
              </Card>
            </FlexContainer>
            <FlexContainer>
              <Card hasShadow>
                <CarbonNeutrality />
              </Card>
            </FlexContainer>
            <FlexContainer>
              <Card hasShadow>
                <CardContentWrapper height={250} width={350}>
                  <ProjectCard
                    heading={'Projects supported so far'}
                    info={'Orange DND Wind Power Private Limited in Rajasthan'}
                    expandLink={'www.google.com'}
                  />
                </CardContentWrapper>
              </Card>
            </FlexContainer>
          </DashboardRowContainer>
          <DashboardRowContainer>
            <FlexContainer>
              <Card hasShadow>
                <CarbonOffset />
              </Card>
            </FlexContainer>
            <FlexContainer>
              <Card hasShadow>
                <CarbonCategory />
              </Card>
            </FlexContainer>
            <FlexContainer>
              <Card hasShadow>
                <CardContentWrapper height={300} width={276}>
                  <div
                    style={{
                      backgroundImage: `url(${ProjectLocation})`,
                      width: '100%',
                      height: '100%',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'contain',
                      marginBottom: '15px',
                      marginLeft: '30px',
                      paddingRight: '30px',
                      overflow: 'hidden',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <FooterLogo text="Coming Soon" />
                  </div>
                </CardContentWrapper>
              </Card>
            </FlexContainer>
          </DashboardRowContainer>
        </Container>
      </Wrapper>
    </>
  );
};

export default CarbonInput;
