import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

import './App.css';
import ProtectedRoute from './components/ProtectedRoute';
import {AuthProvider, useAuthProvider} from './context/Auth/Auth.Context';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';

function App() {
  const {user} = useAuthProvider();
  return (
    <>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Login formType={'signIn'} />} />
            {/*<Route*/}
            {/*  path="forgot-password"*/}
            {/*  element={<Login formType={'forgot-password'} />}*/}
            {/*/>*/}
            {/*<Route*/}
            {/*  path="reset-password"*/}
            {/*  element={<Login formType={'reset-password'} />}*/}
            {/*/>*/}
            <Route element={<ProtectedRoute user={user} />}>
              <Route path="dashboard" element={<Dashboard />} />
              {/*  <Route path="employees" element={<Employees />} />*/}
            </Route>
            {/*todo this needs to change to the resource page and moved under a protected route*/}
            {/* <Route path="resources" element={<Public />} /> */}
            {/*todo we need to add the 404 pages here*/}
            {/* <Route element={<Public />} /> */}
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
