import React from 'react';

import Banner from '../Banner/Banner';
import CarbonOffset from '../CarbonOffset';
import EcoRewards from '../EcoRewards';

const DashboardSections = ({section, ...rest}: any) => {
  switch (section) {
    case 'banner':
      return <Banner {...rest} />;
    case 'carbonOffset':
      return <CarbonOffset {...rest} />;
    case 'rewards':
      return <EcoRewards {...rest} />;
    case 'summaries':
    default:
      return null;
  }
};

export default DashboardSections;
