import {
  Dropdown,
  Filter as FilterComponent,
} from '@ekko-earth/dashboard-components';
import {SelectOption} from '@ekko-earth/dashboard-components/dist/esm/types/components/Select/Select.interface';
import React, {useEffect, useState} from 'react';

import {useAuthProvider} from '../../context/Auth/Auth.Context';
import {useFilterProvider} from '../../context/Filter/Filter.Context';
import {FilterInterface} from './Filter.Interface';
import {Container, FilterComponentContainer} from './Filter.styles';

const Filter = () => {
  const {filters, setFilters} = useFilterProvider();
  const {brands} = useAuthProvider();
  const [currentFilter, setCurrentFilters] = useState<FilterInterface | null>({
    option: [],
    startDate: '',
    endDate: '',
  });
  const [applyFilters, setApplyFilters] = useState<boolean>(false);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [placeholder, setPlaceholder] = useState<string>('All');
  const [options, setOptions] = useState<SelectOption[] | undefined>(undefined);

  // set the initial state for the filter box
  useEffect(() => {
    const newFilters: any = {...filters};
    newFilters['option'] = newFilters['brandId'];
    delete newFilters['brandId'];
    setCurrentFilters(newFilters as unknown as FilterInterface);
  }, [filters]);
  // getting all the brands
  useEffect(() => {
    if (brands.length > 1) {
      // @ts-ignore
      const brandOptions = brands.map(({_id, name}) => ({
        label: name,
        value: _id,
      }));

      if (brands.length > 0) {
        brandOptions.unshift({label: 'All', value: 'all'});
      }
      setOptions(brandOptions);
    }
  }, [brands]);

  useEffect(() => {
    if (applyFilters && currentFilter) {
      setApplyFilters(false);
      const newFilters: any = {
        brandId:
          currentFilter['option'][0] === 'all' ? [] : currentFilter['option'],
        ...currentFilter,
      };
      delete newFilters.option;

      let {name = 'All'} =
        brands.find(
          (brand: {_id: string | number | undefined}) =>
            brand._id === currentFilter.option[0],
        ) ?? {};

      setPlaceholder(name);
      setFilters(newFilters);
    }
  }, [currentFilter, brands, applyFilters, filters]);

  const handleOpenChange = (state: any) => {
    setIsFilterOpen(state);
  };

  const handleFilterChange = (e: any) => {
    setCurrentFilters(e);
    setApplyFilters(true);
    setIsFilterOpen(false);
  };

  return (
    currentFilter && (
      <Container>
        <Dropdown
          label={'Filter'}
          stopDropdownPropagation={true}
          isDropdownOpen={isFilterOpen}
          handleDropdownOpen={handleOpenChange}>
          <FilterComponentContainer>
            <FilterComponent
              label={'Brands'}
              filterState={currentFilter}
              handleChange={handleFilterChange}
              placeholder={placeholder}
              selectOptions={options}
            />
          </FilterComponentContainer>
        </Dropdown>
      </Container>
    )
  );
};

export default Filter;
