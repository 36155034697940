import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';

import {useAuthProvider} from '../../context/Auth/Auth.Context';

// todo fix this up properly
const ProtectedRoute = ({redirectPath = '/'}: any) => {
  const {user} = useAuthProvider();
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};
export default ProtectedRoute;
