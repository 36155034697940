import {CardHeader, LineGraph} from '@ekko-earth/dashboard-components';

import {useGraphProvider} from '../../../context/Graph/Graph.Context';
import {CardContentWrapper} from '../../DashboardSections/DashboardSection.styles';
import NoData from '../../NoData';
import {SkeletonLoader} from '../../SkeletonLoader';
import {CardContent} from '../CarbonOffset.styles';

const CarbonFootprint = () => {
  const {carbonFootprint: {loading = true, data = {}, total = 0} = {}} =
    useGraphProvider();

  if (loading) {
    return (
      <CardContentWrapper height={300} width={370}>
        <SkeletonLoader />
      </CardContentWrapper>
    );
  }

  return (
    <>
      <CardHeader heading={'Total Carbon Footprint'} />
      <CardContent>
        <CardContentWrapper height={250} width={350}>
          {!loading && data && data.length > 1 ? (
            <LineGraph
              data={[data]}
              header={`${Number(total).toLocaleString()}`}
              subHeader={'CO2/Kg'}
              customWidth={350}
            />
          ) : (
            !loading &&
            data && <NoData componentName={'Carbon Footprint data'} />
          )}
        </CardContentWrapper>
      </CardContent>
    </>
  );
};

export default CarbonFootprint;
