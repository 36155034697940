import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Container = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
`;

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
`;

const CardContent = styled.div`
  padding: 16px;
  padding-top: 0;
`;

export {Container, FlexContainer, CardContent, Wrapper};
