import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';

import {
  getCarbonEquivalency,
  getCarbonFootprint,
  getCarbonImpactCategories,
  getCarbonNeutrality,
  getCarbonOffset,
  getTotalBottles,
  getTotalTrees,
} from '../../graphql/queries';
import {FilterProps} from '../Filter/Filter.Interface';
import {
  CarbonFootprintData,
  GetCarbonFootprintResponse,
  GetCarbonOffsetResponse,
} from './Graph.Interface';

const fetchCarbonFootprint = async (
  input: FilterProps,
): Promise<{data: CarbonFootprintData[]; total: number}> => {
  try {
    const response = (await API.graphql(
      graphqlOperation(getCarbonFootprint, {input}),
    )) as GraphQLResult<GetCarbonFootprintResponse>;

    if (response?.errors && response?.errors.length > 0) {
      throw new Error(response.errors[0]?.message);
    }

    const {data = [], total = 0} =
      response?.data?.getCarbonFootprint?.carbonImpactData || {};

    return {data, total};
  } catch (e) {
    console.log(e);
    throw new Error('Unable to retrieve Carbon footprint data.');
  }
};

const fetchCarbonOffset = async (input: FilterProps) => {
  try {
    const response = (await API.graphql(
      graphqlOperation(getCarbonOffset, {input}),
    )) as GraphQLResult<GetCarbonOffsetResponse>;

    if (response?.errors && response?.errors.length > 0) {
      throw new Error(response.errors[0]?.message);
    }

    const {data = [], total = 0} =
      response?.data?.getCarbonOffset?.carbonOffsetData || {};

    return {data, total};
  } catch (e) {
    console.log(e);
    throw new Error('Unable to retrieve Carbon Offset data.');
  }
};

const fetchCarbonBreakdown = async (input: FilterProps) => {
  try {
    const {
      // @ts-ignore
      data: {
        getCarbonImpactCategories: {
          carbonCategoriesImpactData: {data, total},
        },
      },
    } = await API.graphql(graphqlOperation(getCarbonImpactCategories, {input}));

    return {data, total};
  } catch (e) {
    console.error(e);
    throw new Error('Unable to find Carbon Breakdown data.');
  }
};

const fetchCarbonEquivalency = async (input: FilterProps) => {
  try {
    const {
      // @ts-ignore
      data: {
        getCarbonEquivalency: {
          carbonEquivalance: {milesByCar, phonesCharged},
        },
      },
    } = await API.graphql(graphqlOperation(getCarbonEquivalency, {input}));

    return {data: {milesByCar, phonesCharged}};
  } catch (e) {
    console.error(e);
    throw new Error('Unable to find Carbon Breakdown data.');
  }
};

const fetchTrees = async (input: FilterProps) => {
  try {
    const {
      // @ts-ignore
      data: {
        getTotalTrees: {data, trees},
      },
    } = await API.graphql(graphqlOperation(getTotalTrees, {input}));

    return {data, total: trees};
  } catch (e) {
    console.error(e);
    throw new Error('Unable to find Trees data.');
  }
};

const fetchBottles = async (input: FilterProps) => {
  try {
    const {
      // @ts-ignore
      data: {
        getTotalBottles: {data, bottles},
      },
    } = await API.graphql(graphqlOperation(getTotalBottles, {input}));

    return {data, total: bottles};
  } catch (e) {
    console.error(e);
    throw new Error('Unable to find Bottles data.');
  }
};

const fetchCarbonNeutrality = async (input: FilterProps) => {
  try {
    const {
      // @ts-ignore
      data: {
        getCarbonNeutrality: {
          carbonNeutrality: {data},
        },
      },
    } = await API.graphql(graphqlOperation(getCarbonNeutrality, {input}));
    // we don't have this for now but to keep the model the same I added this
    const total = 0;
    return {data, total};
  } catch (e) {
    console.error(e);
    console.log('Unable to retrieve carbon neutrality data.');
  }
};

export {
  fetchCarbonFootprint,
  fetchCarbonOffset,
  fetchCarbonBreakdown,
  fetchCarbonEquivalency,
  fetchBottles,
  fetchTrees,
  fetchCarbonNeutrality,
};
