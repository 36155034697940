import {CardHeader, MultilineGraph} from '@ekko-earth/dashboard-components';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import {useGraphProvider} from '../../../context/Graph/Graph.Context';
import {CardContentWrapper} from '../../DashboardSections/DashboardSection.styles';
import Loader from '../../Loader';
import NoData from '../../NoData';
import {SkeletonLoader} from '../../SkeletonLoader';
import {CardContent} from '../CarbonOffset.styles';

const CarbonNeutrality = () => {
  const {carbonNeutrality} = useGraphProvider();
  const {data, loading} = carbonNeutrality || {
    data: [],
    loading: false,
  };

  if (loading) {
    return (
      <CardContentWrapper height={300} width={370}>
        <SkeletonLoader />
      </CardContentWrapper>
    );
  }

  return (
    <>
      <CardHeader heading={'Carbon Neutrality'} />
      <CardContent>
        <CardContentWrapper height={250} width={350}>
          {!loading && data && data.length > 1 ? (
            <MultilineGraph data={data} />
          ) : (
            <NoData componentName={'Carbon Neutrality data'} />
          )}
        </CardContentWrapper>
      </CardContent>
    </>
  );
};

export default CarbonNeutrality;
