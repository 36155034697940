import {Text} from '@ekko-earth/dashboard-components';
import React from 'react';

import {useAuthProvider} from '../../context/Auth/Auth.Context';
import Filter from '../Filter';
import {Container, Greeting, Subheader} from './Banner.styles';

const Banner = () => {
  const {
    user: {
      info: {firstName},
    },
  } = useAuthProvider();
  return (
    <Container>
      <Greeting>
        <Text variant="secondary" fontSize="24" fontWeight="bold">
          Hello, {firstName}
        </Text>
        <Subheader fontSize="14">Welcome to positive change!</Subheader>
      </Greeting>
      <Filter />
    </Container>
  );
};

export default Banner;
