import {CardHeader, EquivalencyCard} from '@ekko-earth/dashboard-components';
import React, {useEffect, useState} from 'react';

import {car, phone} from '../../assets/images';
import {useGraphProvider} from '../../context/Graph/Graph.Context';
import {CardContent} from '../CarbonOffset/CarbonOffset.styles';
import Loader from '../Loader';
import NoData from '../NoData';
import {SkeletonLoader} from '../SkeletonLoader';
import {
  Container,
  NoDataContainer,
  VerticalLine,
} from './EquivalencyCard.styles';

const Equivalency = () => {
  const {carbonEquivalency} = useGraphProvider();
  const {loading, data} = carbonEquivalency || {
    loading: false,
    data: {},
  };

  if (loading) {
    return <SkeletonLoader />;
  }

  return (
    <>
      <CardHeader heading={'CO2 Category Breakdown'} />
      <CardContent>
        <Container>
          <EquivalencyCard
            title={'Miles by car'}
            count={data?.milesByCar || 0}
            image={car as any}
          />
          <VerticalLine />
          <EquivalencyCard
            title={'Phones Charged'}
            count={data?.phonesCharged || 0}
            image={phone as any}
          />

          {!loading && !456 && (
            <NoDataContainer>
              <NoData componentName={'Equivalency Cards data.'} />
            </NoDataContainer>
          )}
        </Container>
      </CardContent>
    </>
  );
};

export default Equivalency;
