import {createContext, useContext, useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';

import {
  FilterContextInterface,
  FilterInterface,
  FilterProps,
} from './Filter.Interface';

const FilterContext = createContext<FilterInterface>({} as FilterInterface);

const FilterProvider = ({children}: FilterContextInterface): JSX.Element => {
  //we need to persist the filters to local storage
  const cookieName = 'ekkoClimate';
  const [filterStore, setFilterStore] = useState<FilterProps | null>(null);
  const [cookies, setCookie] = useCookies([cookieName]);

  //get the initial state of the filter
  useEffect(() => {
    if (!filterStore && cookies[cookieName]) {
      setFilterStore(cookies[cookieName]);
    } else if (!filterStore && !cookies[cookieName]) {
      setFilterStore({
        brandId: [],
        startDate: '',
        endDate: '',
      });
    }
  }, [filterStore, cookies]);

  // handling the persistence
  useEffect(() => {
    if (filterStore) {
      setCookie(cookieName, JSON.stringify(filterStore), {
        maxAge: 86400,
        path: '/',
      });
    }
  }, [filterStore, setCookie]);

  const handleFilterUpdate = (newFilters: any) => {
    setFilterStore((prevFilters: any) => {
      return {
        ...prevFilters,
        ...newFilters,
      };
    });
  };
  const value = {
    filters: filterStore,
    setFilters: handleFilterUpdate,
  };
  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
};

const useFilterProvider = () => useContext(FilterContext);

export {FilterProvider, useFilterProvider};

export default FilterContext;
