import {API, Auth, graphqlOperation} from 'aws-amplify';
import {Cookies} from 'react-cookie';

import {getUserByCognitoId} from '../../graphql/queries';

const cookiePrefix = 'ecau'; // Ekko Climate Api User
const cookies = new Cookies();

/*
TODO we should add some decryption to the cookie retrieval
 */
const getCookie = async () => {
  const sub = await getUserInfo();
  const myCookie = cookies.get(getCookieName(sub));
  return myCookie;
};

/*
TODO we should add some encryption to the cookie setting
 */
const setCookie = async (value: any) => {
  const sub = await getUserInfo();
  cookies.set(getCookieName(sub), value, {path: '/'});
};

async function getUserInfo() {
  const {
    attributes: {sub},
  } = await Auth.currentUserInfo();
  return sub;
}
function getCookieName(sub: string): string {
  return `${cookiePrefix}-${sub}`;
}

async function maybeGetUserInfo() {
  const cookie = await getCookie();
  if (!cookie) {
    return false;
  }
  return cookie;
}

const fetchEmployerByCognitoId = async () => {
  try {
    const cachedUser = await maybeGetUserInfo();
    if (cachedUser) {
      return cachedUser;
    }
    const {
      // @ts-ignore
      data: {
        getUserByCognitoId: {user, settings, brands},
      },
    } = await API.graphql(graphqlOperation(getUserByCognitoId));
    await setCookie({user, brands, settings});

    return {user, brands, settings};
  } catch (err) {
    console.error('error fetching employer information', err);
    throw new Error('Unable to retrieve user information');
  }
};

export {fetchEmployerByCognitoId};
