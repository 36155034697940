import {View} from '@ekko-earth/dashboard-components';
import {useState} from 'react';

import DashboardSections from '../../components/DashboardSections';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import {FilterProvider} from '../../context/Filter/Filter.Context';
import {GraphProvider} from '../../context/Graph/Graph.Context';
import {Container} from './Dashboard.styles';

const Dashboard = () => {
  const [sections] = useState([
    'banner',
    'summaries',
    'carbonFootprint',
    'carbonOffset',
    'rewards',
  ]);
  return (
    <FilterProvider>
      <Header />
      <div style={{width: '1168px', margin: 'auto'}}>
        <Container>
          <GraphProvider>
            {sections.map((section) => (
              <DashboardSections key={section} section={section} />
            ))}
          </GraphProvider>
        </Container>
      </div>
      <Footer />
    </FilterProvider>
  );
};

export default Dashboard;
