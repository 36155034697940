import {Text} from '@ekko-earth/dashboard-components';
import styled from 'styled-components';

const Container = styled.section`
  display: flex;
  justify-content: space-between;
`;

const Greeting = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Subheader = styled(Text)`
  padding-top: 10px;
`;

export {Container, Greeting, Subheader};
