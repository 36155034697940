import {CardHeader, LineGraph} from '@ekko-earth/dashboard-components';
import {useEffect, useState} from 'react';

import {useGraphProvider} from '../../../context/Graph/Graph.Context';
import {CardContentWrapper} from '../../DashboardSections/DashboardSection.styles';
import Loader from '../../Loader';
import NoData from '../../NoData';
import {SkeletonLoader} from '../../SkeletonLoader';
import {CardContent} from '../CarbonOffset.styles';

const CarbonOffset = () => {
  const {carbonOffset: {loading = true, data = [], total = 0} = {}} =
    useGraphProvider();

  if (loading) {
    return (
      <CardContentWrapper height={300} width={370}>
        <SkeletonLoader />
      </CardContentWrapper>
    );
  }

  return (
    <>
      <CardHeader heading={'Total Carbon Offset'} />
      <CardContent>
        <CardContentWrapper height={250} width={350}>
          {!loading && data && data.length > 0 && (
            <LineGraph
              data={[data]}
              header={`${Number(total).toLocaleString()}`}
              subHeader={'CO2/Kg'}
              customWidth={350}
            />
          )}
          {!loading && (!data || !data.length) && (
            <NoData componentName={'Carbon Offset data'} />
          )}
        </CardContentWrapper>
      </CardContent>
    </>
  );
};

export default CarbonOffset;
